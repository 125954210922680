.check-back{
    width: 100vw;
    height: 100vh;   
    max-height: 91vh;  
    object-fit: contain;  
}

/* For tablets and smaller screens */
@media (max-width: 768px) {
    .check-back {
        width: 100vw;
        height: 90vh; /* Adjust height for smaller screens */
        max-height: 400px; /* Adjust max-height */
    }
}

/* For mobile devices */
@media (max-width: 480px) {
    .check-back {
        width: 100vw;
        height: 80vh; /* Further reduce height for mobile */
        max-height: 350px; /* Adjust max-height */
    }
}
