*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
  
   
  #logo {
       
    animation: fadeInOut 5s ease-in ; /* Animation */
    opacity: 0; /* Initially hidden */
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  } 
  
    #email:hover::placeholder {
      color: navy;
    }
    #password:hover::placeholder {
      color: navy;
    }
  /* Default styles (for mobile and smaller devices) */
.login-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  z-index: -10;
  background-size: cover;
}

/* Media query for medium devices and larger */
@media (min-width: 768px) {
  .login-video {
    height: 100%;
    width: auto;
  }
}

/* Media query for large devices and larger */
@media (min-width: 1024px) {
 .login-video{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
