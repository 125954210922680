.scrolling-text-container {
  overflow: hidden;
  display: flex;
  justify-content: flex-start; /* Replace 'start' with 'flex-start' */
  align-items: center;
  margin-top: calc(100vh - 98vh);
}
@media (min-width: 768px) {
  .scrolling-text-container{
    margin-top: calc(100vh - 98vh); /* Adjust as needed */
  }
}
  
  .scrolling-text-content {
    display: flex;
    flex-shrink: 0;
    animation: scroll 60s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }



  @font-face {
    font-family: "preload-image";
    src: url('../image/bghome10.svg');
}
.image {
    background-image: url('../image/bghome10.svg');
    background-size: cover; 
    height:calc( 100vh - 55px);
    background-position: fixed;
    
  } 
  .blureffect{
    animation: blurBackground 2s ease-in-out forwards;
  }
  
  /* Animation to blur and then return to normal */
  @keyframes blurBackground {
      0% {
          filter: blur(0px);
      }
      50% {
          filter: blur(10px); 
      }
      100% {
          filter: blur(0);
      }
  } 
 
.glass {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.117); /* semi-transparent white */
      backdrop-filter: blur(5px); /* glass effect */
      padding: 20px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 430px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .popup-message{
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .popup-button{
    border: 2px double rgb(131, 64, 231);
    background-color: rgb(124, 124, 245);
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 7px;
    width: 5rem;
  }
/* termas style */
.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears below the popup */
}

.terms-content {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 430px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.action-buttons{
  gap: 170px;
  margin-top: 10px;
  justify-content: center;
  display: flex;
  }
.decline-btn{
  border: none;
  background-color: rgb(245, 43, 25);
  }
    
  .accept-btn {
    background-color: rgb(3, 73, 148);
    transition: filter2s ease;
    width: 4rem;
  }
  
  .accept-btn:hover, .decline-btn:hover {
    filter: brightness(2); /* Increases brightness by 2 times */
  }
  
.decline-btn ,
.accept-btn{
  padding: 6px ;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  color:aliceblue;
  }
.terms-pgh{
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  overflow-y: auto; /* Enable horizontal scrolling */
  max-height: 250px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
b{
  font-size: small;
  font-weight: bold;
}