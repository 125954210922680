@font-face {
  font-family: "preload-image";
  src: url('../image/login_bg.svg');
}

.images{
  height:calc( 100vh - 70px);
  background-image: url('../image/login_bg.svg');
  background-position: fixed;
  animation: blurBackground 4s ease-in-out forwards;
  background-size: cover; 
}

/* Animation to blur and then return to normal */
@keyframes blurBackground {
    0% {
        filter: blur(0);
    }
    50% {
        filter: blur(10px); /* You can adjust the blur level */
    }
    100% {
        filter: blur(0);
    }
  }

 
  .box div{
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: 6px solid rgba(255,255,255,0.8);
  }
  .box div:nth-child(1){
    top: 12%;
    left:42%;
    animation: animate 10s linear infinite;
  }
  .box div:nth-child(2){
    top: 70%;
    left:50%;
    animation: animate 7s linear infinite;
  }
  .box div:nth-child(3){
    top: 17%;
    left:6%;
    animation: animate 9s linear infinite;
  }
  .box div:nth-child(4){
    top: 20%;
    left:60%;
    animation: animate 10s linear infinite;
  }
  .box div:nth-child(5){
    top:67%;
    left:10%;
    animation: animate 6s linear infinite;
  }
  .box div:nth-child(6){
    top:80%;
    left:70%;
    animation: animate 12s linear infinite;
  }
  .box div:nth-child(7){
    top:60%;
    left:80%;
    animation: animate 15s linear infinite;
  }
  .box div:nth-child(8){
    top:32%;
    left:25%;
    animation: animate 12s linear infinite;
  }
  .box div:nth-child(9){
    top:60%;
    left:30%;
    animation: animate 6s linear infinite;
  }
  .box div:nth-child(10){
    top:80%;
    left:20%;
    animation: animate 18s linear infinite;
  }
  @keyframes animate{
    0%{
      transform: scale(0) translateY(0) rotate(0);
      opacity: 1;
    }
    100%{
      transform: scale(1.3) translateY(-50px) rotate(360deg);
      opacity:0;
    }
  }