.tableau-container {
  width: 100%;
  max-width: 96vw;
  height: 100%;
  max-height: 94vh; 
  display: flex;
  position:fixed;
  overflow: hidden;
}

tableau-viz {
  width: 100%;
  height: 100%;
}

p {
  font-size: 18px;
  color: #333;
}