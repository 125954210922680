
.background{
background-color: black;
background-size: cover;
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95.6%;
  height: 89%; /* Ensure the container takes up the full space */
  background-color: black; /* Optional: Set the background to black to match the video */
  position: absolute;
}

.videos {
  max-width: 95.6%;
  max-height: 89%;
  object-fit: cover; /* Ensure the video covers the area */
}


  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .video-container, .videos {
      width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .video-container, .videos {
      width: 100%;
      height: auto;
      margin-left: 0;
      margin-top: 0;
    }
  }
  