*{
  margin: 0;
  padding:0;
  box-sizing: border-box ;
}
/* Sidebar.css */

.sidebar-container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  background-color: #4f76b4; 
  /* position: relative; */
  /* z-index: 10; */
   height: 100vh; 
   min-height: 100%;
 }
.sidebar-expand{
  height: 100vh;
  background-color: #4f76b4 ;
  flex: 1;
  transition-duration: 500ms;
  padding: 8px;

}

/* .logout-container{ */
  /* justify-content: center;
  align-items: center; */
  /* background-color: #4f76b4 ;
  display: flex;
  padding: 12px 0;
  margin-top: 7px;
  margin-left: 1px;
} */